import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm'

const NewsletterPage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='buyer'
      subheader={<FormattedMessage id='nav.buyerDashB' />}
      header={
        <span className='bold'>
          <FormattedMessage id='newsletter.headerA' />
        </span>
      }
    >
      <SEO
        description={intl.formatMessage({
          id: 'metaTags.newsletterDescription'
        })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'newsletter.headerA' })}
      />
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <h2>
              <FormattedMessage id='newsletter.subHeaderA' />
            </h2>
          </Col>
          <Col md={6}>
            <SubscriptionForm />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NewsletterPage
